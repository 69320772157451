define('ember-feature-flags/features', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var camelize = _ember['default'].String.camelize;

  exports['default'] = _ember['default'].Object.extend({

    init: function init() {
      this._super.apply(this, arguments);
      this._flags = _ember['default'].create(null);

      this.setUnknownProperty = function (key) {
        throw new Error("Please use enable/disable to set feature flags. You attempted to set " + key);
      };
    },

    setup: function setup(flags) {
      var normalizedFlags = _ember['default'].create(null);
      for (var flag in flags) {
        if (flags.hasOwnProperty(flag)) {
          // Use !! to ensure the properties are all booleans.
          normalizedFlags[this.normalizeFlag(flag)] = !!flags[flag];
        }
      }
      this._flags = normalizedFlags;
    },

    normalizeFlag: function normalizeFlag(flag) {
      return camelize(flag);
    },

    enable: function enable(flag) {
      var normalizedFlag = this.normalizeFlag(flag);
      this._flags[normalizedFlag] = true;
      this.notifyPropertyChange(normalizedFlag);
    },

    disable: function disable(flag) {
      var normalizedFlag = this.normalizeFlag(flag);
      this._flags[normalizedFlag] = false;
      this.notifyPropertyChange(normalizedFlag);
    },

    enabled: function enabled(feature) {
      _ember['default'].deprecate('[ember-feature-flags] enabled has been deprecated in favor of isEnabled');
      return this.isEnabled(feature);
    },

    isEnabled: function isEnabled(feature) {
      var isEnabled = this._featureIsEnabled(feature);
      if (this.logFeatureFlagMissEnabled() && !isEnabled) {
        this.logFeatureFlagMiss(feature);
      }
      return isEnabled;
    },

    _featureIsEnabled: function _featureIsEnabled(feature) {
      var normalizeFeature = this.normalizeFlag(feature);
      return this._flags[normalizeFeature] || false;
    },

    logFeatureFlagMissEnabled: function logFeatureFlagMissEnabled() {
      return !!window.ENV && !!window.ENV.LOG_FEATURE_FLAG_MISS;
    },

    logFeatureFlagMiss: function logFeatureFlagMiss(feature) {
      if (console && console.info) {
        console.info('Feature flag off:', feature);
      }
    },

    unknownProperty: function unknownProperty(key) {
      return this.isEnabled(key);
    }

  });
});