define('ember-waypoints/components/way-point', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var getProperties = _ember['default'].getProperties;
  var bind = _ember['default'].run.bind;
  var on = _ember['default'].on;
  var isNone = _ember['default'].isNone;

  exports['default'] = _ember['default'].Component.extend({
    contextElement: null,
    offset: null,
    triggerOnce: null,
    continuous: null,
    horizontal: null,

    waypoint: function waypoint() {
      if (typeof document === 'undefined') {
        return;
      }

      var element = this.$();

      if (!element.waypoint) {
        return;
      }

      element.waypoint.apply(element, arguments);
    },

    setupWaypoint: on('didInsertElement', function () {
      this.waypoint(this.buildOptions());
    }),

    teardownWaypoint: on('willDestroyElement', function () {
      this.waypoint('destroy');
    }),

    buildOptions: function buildOptions() {
      var options = getProperties(this, ['contextElementId', 'offset', 'triggerOnce', 'continuous', 'horizontal']);
      options.handler = bind(this, this.waypointTriggered);

      for (var option in options) {
        if (isNone(options[option])) {
          delete options[option];
        }
      }

      if (options.contextElementId) {
        options.context = document.getElementById(options.contextElementId);
        delete options.contextElementId;
      }

      return options;
    },

    waypointTriggered: function waypointTriggered(direction) {
      this.sendAction('on-' + direction, this);
      this.sendAction('action', direction, this);
    }
  });
});