define('ember-g-map/components/g-map-marker', ['exports', 'ember', 'ember-g-map/templates/components/g-map-marker', 'ember-g-map/components/g-map'], function (exports, _ember, _emberGMapTemplatesComponentsGMapMarker, _emberGMapComponentsGMap) {
  'use strict';

  var isEmpty = _ember['default'].isEmpty;
  var isPresent = _ember['default'].isPresent;
  var observer = _ember['default'].observer;
  var computed = _ember['default'].computed;
  var run = _ember['default'].run;
  var assert = _ember['default'].assert;
  var typeOf = _ember['default'].typeOf;

  var GMapMarkerComponent = _ember['default'].Component.extend({
    layout: _emberGMapTemplatesComponentsGMapMarker['default'],
    classNames: ['g-map-marker'],

    map: computed.alias('mapContext.map'),

    init: function init() {
      this._super(arguments);
      this.infowindow = null;
      if (isEmpty(this.get('group'))) {
        this.set('group', null);
      }

      var mapContext = this.get('mapContext');
      assert('Must be inside {{#g-map}} component with context set', mapContext instanceof _emberGMapComponentsGMap['default']);

      mapContext.registerMarker(this);
    },

    didInsertElement: function didInsertElement() {
      this._super();
      if (isEmpty(this.get('marker')) && typeof FastBoot === 'undefined') {
        var marker = new google.maps.Marker();
        this.set('marker', marker);
      }
      this.setPosition();
      this.setZIndex();
      this.setIcon();
      this.setDraggable();
      this.setLabel();
      this.setTitle();
      this.setMap();
      this.setOnClick();
      this.setOnDrag();
    },

    willDestroyElement: function willDestroyElement() {
      this.unsetMarkerFromMap();
      this.get('mapContext').unregisterMarker(this);
    },

    registerInfowindow: function registerInfowindow(infowindow, openEvent, closeEvent) {
      this.set('infowindow', infowindow);
      this.attachOpenCloseEvents(infowindow, openEvent, closeEvent);
    },

    unregisterInfowindow: function unregisterInfowindow() {
      this.set('infowindow', null);
    },

    attachOpenCloseEvents: function attachOpenCloseEvents(infowindow, openEvent, closeEvent) {
      var marker = this.get('marker');
      if (openEvent === closeEvent) {
        this.attachTogglingInfowindowEvent(marker, infowindow, openEvent);
      } else {
        this.attachOpenInfowindowEvent(marker, infowindow, openEvent);
        this.attachCloseInfowindowEvent(marker, infowindow, closeEvent);
      }
    },

    attachOpenInfowindowEvent: function attachOpenInfowindowEvent(marker, infowindow, event) {
      if (isPresent(event)) {
        marker.addListener(event, function () {
          return infowindow.open();
        });
      }
    },

    attachCloseInfowindowEvent: function attachCloseInfowindowEvent(marker, infowindow, event) {
      if (isPresent(event)) {
        marker.addListener(event, function () {
          return infowindow.close();
        });
      }
    },

    attachTogglingInfowindowEvent: function attachTogglingInfowindowEvent(marker, infowindow, event) {
      if (isPresent(event)) {
        marker.addListener(event, function () {
          if (infowindow.get('isOpen')) {
            infowindow.close();
          } else {
            infowindow.open();
          }
        });
      }
    },

    unsetMarkerFromMap: function unsetMarkerFromMap() {
      var marker = this.get('marker');
      if (isPresent(marker)) {
        marker.setMap(null);
      }
    },

    mapWasSet: observer('map', function () {
      run.once(this, 'setMap');
    }),

    setMap: function setMap() {
      var map = this.get('map');
      var marker = this.get('marker');

      if (isPresent(marker) && isPresent(map)) {
        marker.setMap(map);
      }
    },

    coordsChanged: observer('lat', 'lng', function () {
      run.once(this, 'setPosition');
    }),

    setPosition: function setPosition() {
      var marker = this.get('marker');
      var lat = this.get('lat');
      var lng = this.get('lng');

      if (isPresent(marker) && isPresent(lat) && isPresent(lng) && typeof FastBoot === 'undefined') {
        var position = new google.maps.LatLng(lat, lng);
        if (isPresent(position)) {
          marker.setPosition(position);
        }
      }
    },

    iconChanged: observer('icon', function () {
      run.once(this, 'setIcon');
    }),

    setIcon: function setIcon() {
      var marker = this.get('marker');
      var icon = this.get('icon');

      if (isPresent(marker) && isPresent(icon)) {
        marker.setIcon(icon);
      }
    },

    zIndexChanged: observer('zIndex', function () {
      run.once(this, 'setZIndex');
    }),

    setZIndex: function setZIndex() {
      var marker = this.get('marker');
      var zIndex = this.get('zIndex');
      if (isPresent(marker) && isPresent(zIndex)) {
        marker.setZIndex(zIndex);
      }
    },

    draggableChanged: observer('draggable', function () {
      run.once(this, 'setDraggable');
    }),

    setDraggable: function setDraggable() {
      var marker = this.get('marker');
      var draggable = this.get('draggable');
      if (isPresent(marker) && isPresent(draggable)) {
        marker.setDraggable(draggable);
      }
    },

    setOnClick: function setOnClick() {
      var _this = this;

      var marker = this.get('marker');
      if (isPresent(marker)) {
        marker.addListener('click', function () {
          return _this.sendOnClick();
        });
      }
    },

    setOnDrag: function setOnDrag() {
      var _this2 = this;

      var marker = this.get('marker');
      marker.addListener('dragend', function (event) {
        var lat = event.latLng.lat();
        var lng = event.latLng.lng();
        if (isPresent(lat) && isPresent(lng) && isPresent(marker)) {
          var position = new google.maps.LatLng(lat, lng);
          marker.setPosition(position);
          _this2.sendOnDrag(lat, lng);
        }
      });
    },

    labelChanged: observer('label', function () {
      run.once(this, 'setLabel');
    }),

    setLabel: function setLabel() {
      var marker = this.get('marker');
      var label = this.get('label');

      if (isPresent(marker) && isPresent(label)) {
        marker.setLabel(label);
      }
    },

    titleChanged: observer('title', function () {
      run.once(this, 'setTitle');
    }),

    setTitle: function setTitle() {
      var marker = this.get('marker');
      var title = this.get('title');

      if (isPresent(marker) && isPresent(title)) {
        marker.setTitle(title);
      }
    },

    sendOnClick: function sendOnClick() {
      var onClick = this.attrs.onClick;

      var mapContext = this.get('mapContext');
      var group = this.get('group');

      if (typeOf(onClick) === 'function') {
        onClick();
      } else {
        this.sendAction('onClick');
      }

      if (isPresent(group)) {
        mapContext.groupMarkerClicked(this, group);
      }
    },

    sendOnDrag: function sendOnDrag(lat, lng) {
      var onDrag = this.attrs.onDrag;

      if (typeOf(onDrag) === 'function') {
        onDrag(lat, lng);
      } else {
        this.sendAction('onDrag', lat, lng);
      }
    },

    closeInfowindow: function closeInfowindow() {
      var infowindow = this.get('infowindow');
      if (isPresent(infowindow)) {
        infowindow.close();
      }
    }
  });

  GMapMarkerComponent.reopenClass({
    positionalParams: ['mapContext']
  });

  exports['default'] = GMapMarkerComponent;
});